import React, { useContext, useState, useEffect } from "react"

const Tilbud = ({ data }) => {
  const [project, setProject] = useState()
  const [isLoading, setLoading] = useState(true)

  let projectId
  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1))
    projectId = params.get("id") // is the string "Jonathan"
  }

  useEffect(() => {
    document.body.classList.add("special-page")

    async function fetchProject() {
      const response = await fetch(
        "/.netlify/functions/fetch-project?id=" + projectId,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      let project = await response.json()
      setLoading(false)
      setProject(project)
    }

    fetchProject()
  }, [])

  return (
    <div className="aluproff">
      <div className="aluproff-header">
        <div>
          <img src="aluproff.jpeg" alt="aluproff" />
        </div>
        <div>
          Telefon: 43600063
          <br />
          Fax: 72620613
          <br />
          Email: salg@aluproff.dk
        </div>
      </div>
      <div className="aluproff-info">
        <div className="aluproff-box" style={{ gridRow: "span 2" }}>
          <div className="aluproff-box-title">Forhandler</div>&Drape
        </div>
        <div className="aluproff-box">
          <div className="aluproff-box-title">Dato</div>
          {new Date().toLocaleDateString()}
        </div>
        <div className="aluproff-box">
          <div className="aluproff-box-title">Lev dato</div>30/10-23
        </div>
        <div className="aluproff-box">
          <div className="aluproff-box-title">Solgt af</div>{" "}
          {project?.project?.fields["Responsible"]?.name}
        </div>
        <div className="aluproff-box">
          <div className="aluproff-box-title">Mærke</div>
          {project?.project?.fields["Aluproff order number"]}
        </div>
      </div>

      <table className="aluproff-table">
        <thead>
          <tr>
            <th>Antal</th>
            <th>Profil</th>
            <th>Komplet el. kn stang</th>
            <th>Farve</th>
            <th>Type buk</th>
            <th>A (lige stænger)</th>
            <th>B</th>
            <th>C</th>
            <th>D</th>
            <th>E</th>
            <th>Nedhængslængde</th>
            <th>Parkering</th>
            <th>Betjeningsside</th>
            <th>Antal glidere</th>
            <th>Vinkel længde</th>
            <th>Antal beslag</th>
            <th>Bemærkning</th>
          </tr>
        </thead>
        <tbody>
          {project &&
            project.windows?.records.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.fields["Finished track details"]}</td>
                  <td>{item.fields["Curtain pole"]}</td>
                  <td>{item.complete}</td>
                  <td>{item.color}</td>
                  <td>{item.type}</td>
                  <td>{item.fields["Finished curtain height"]}</td>
                  <td>{item.b}</td>
                  <td>{item.c}</td>
                  <td>{item.d}</td>
                  <td>{item.e}</td>
                  <td>{item.hangingLength}</td>
                  <td>{item.parking}</td>
                  <td>{item.controlSide}</td>
                  <td>{item.gliders}</td>
                  <td>{item.angleLength}</td>
                  <td>{item.brackets}</td>
                  <td>{item.note}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default Tilbud
